// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-toolbar-background: #f5f5f53d!important;
  /** primary **/
  --ion-color-primary: #333;
  --ion-color-primary-rgb: 28,28,28;
  --ion-color-primary-contrast: #f5f5f5;
  --ion-color-primary-contrast-rgb: 245,245,245;
  --ion-color-primary-shade: #191919;
  --ion-color-primary-tint: #1C1C1C;

  /** secondary **/
  --ion-color-secondary: #f5f5f5;
  --ion-color-secondary-rgb: 245,245,245;
  --ion-color-secondary-contrast: #333;
  --ion-color-secondary-contrast-rgb: 31,31,31;
  --ion-color-secondary-shade: #cccc;
  --ion-color-secondary-tint: #999;
  
  /** gold **/
  --ion-color-gold: #dfb24f;
  --ion-color-gold-rgb: 223,178,79;
  --ion-color-gold-contrast: #000000;
  --ion-color-gold-contrast-rgb: 0,0,0;
  --ion-color-gold-shade: #d4af37;
  --ion-color-gold-tint: #d8b74b;

   /** gold-white **/
   --ion-color-gold-white: #d4af37;
   --ion-color-gold-white-rgb: 212,175,55;
   --ion-color-gold-white-contrast: #ffffff;
   --ion-color-gold-white-contrast-rgb: 0,0,0;
   --ion-color-gold-white-shade: #bb9a30;
   --ion-color-gold-white-tint: #d8b74b;
 
  /** tertiary **/
  --ion-color-tertiary: #4284f3;
  --ion-color-tertiary-rgb: 66, 132, 243;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #009ae0;
  --ion-color-tertiary-tint: #1ab7ff;

  /** success **/
  --ion-color-success: #33a802;
  --ion-color-success-rgb: 51, 168, 2;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #1cc25d;
  --ion-color-success-tint: #36e079;

  /** warning **/
  --ion-color-warning: #fabb04;
  --ion-color-warning-rgb: 250, 187, 4;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #fcc217;
  --ion-color-warning-tint: #d6a002;

  /** danger **/
  --ion-color-danger: #f4344f;
  --ion-color-danger-rgb: 244,52,79;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d72e46;
  --ion-color-danger-tint: #f54861;

  /** lightest **/
  --ion-color-lightest: #FFFFFF;
  --ion-color-lightest-rgb: 255,255,255;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-shade-rgb: 215,216,218;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-tint-rgb: 245,246,249;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-shade-rgb: 134,136,143;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-medium-tint-rgb: 162,164,171;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 28, 28, 28;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-shade-rgb: 30,32,35;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-tint-rgb: 56,58,62;

  /** darkest **/
  --ion-color-darkest: #000000;
  --ion-color-darkest-rgb: 0,0,0;
  $range-ios-slider-height: 100px
}
// its a custom sook 

.ion-color-gold {
  --ion-color-base: var(--ion-color-gold);
  --ion-color-base-rgb: var(--ion-color-gold-rgb);
  --ion-color-contrast: var(--ion-color-gold-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-shade);
  --ion-color-tint: var(--ion-color-gold-tint);
}


.ion-color-gold-white {
  --ion-color-base: var(--ion-color-gold-white);
  --ion-color-base-rgb: var(--ion-color-gold-white-rgb);
  --ion-color-contrast: var(--ion-color-gold-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-gold-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-gold-white-shade);
  --ion-color-tint: var(--ion-color-gold-white-tint);
}

:root {
  /* Set the font family of the entire app */
  --ion-font-family: 'Rubik', sans-serif;

  --app-background: #FFFFFF;
  --app-background-shade: var(--ion-color-secondary);
  --app-background-alt: var(--ion-color-primary);
  --app-background-whitesmoke: var(--ion-color-secondary);
  --app-background-alt-shade: var(--ion-color-primary-shade);

  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --app-narrow-radius: 4px;
  --app-fair-radius: 8px;
  --app-broad-radius: 12px;
  --ion-toolbar-background: #f5f5f53d!important;


}
ion-toolbar{
  background-color: #f5f5f53d;
}

html.ios {
  --app-header-height: 44px;
}

html.md {
  --app-header-height: 56px;
}



.dark-theme {
  --app-background-shade: var(--ion-color-secondary);
  ion-thumbnail img{
    border:medium solid whitesmoke;
    background-color:white
    
  }
  ion-avatar img{
    border:thin solid whitesmoke;
    background-color:white
    
  }
  ion-icon {
    color: whitesmoke !important;
  }
  
  ion-tab-bar{
    --background: rgb(0 0 0 / 60%)!important;
  }

  ion-icon .apple{
    color: #333;
  
}
ion-content,ion-toolbar {
  --background: #333;
}
  --ion-color-primary: whitesmoke;
  --ion-color-primary-rgb: 66,140,255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #444;
  --ion-color-primary-tint: #666;

  --ion-color-secondary: #333;
  --ion-color-secondary-rgb: 35,35,35;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #666;
  --ion-color-secondary-tint: #999;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106,100,255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47,223,117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255,213,52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255,73,97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244,245,248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0,0,0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-shade-rgb: 215,216,218;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-tint-rgb: 245,246,249;
}

/*
 * iOS Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.ios {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0,0,0;
.apple
{
  ion-icon{
    color: #333;
  }
}
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;

  --ion-toolbar-background: #0d0d0d;

  --ion-item-background: #000000;
}


/*
 * Material Design Dark Theme
 * ----------------------------------------------------------------------------
 */

.dark-theme.md {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18,18,18;

  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255,255,255;

  --ion-border-color: #222222;

  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;

  --ion-item-background: #1e1e1e;

  --ion-toolbar-background: #1f1f1f;

  --ion-tab-bar-background: #1f1f1f;
}

