/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// EXTRA GLOBAL STYLES
// Add custom Ionic Colors
@import "./theme/custom-ion-colors.scss";
// Add base app styles
@import "./theme/app-defaults.scss";
// Add base shell styles
@import "./theme/shell-defaults.scss";

.mediaBody{
  background-color: var(--ion-color-light);
}
::-webkit-scrollbar {
  display: block;
}
.scrollX {
  flex: 0 0 auto !important;
  display: flex !important;
  flex-wrap: nowrap !important;
  -ms-overflow-style: scroll !important;
  box-sizing: border-box;
	
	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
}

.ion-padding
{  
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 20px;
  --padding-top: 20px;
}

:root { // hree we add
  --page-margin: var(--app-narrow-margin);
  --page-border-radius: var(--app-fair-radius);

  --page-actions-padding: 5px;
  --page-friends-gutter: calc(var(--page-margin) / 2);
  --page-pictures-gutter: calc(var(--page-margin) / 2);
}

// Note:  All the CSS variables defined below are overrides of Ionic elements CSS Custom Properties
.user-details-section {
  --ion-grid-column-padding: 0px;

  margin: var(--page-margin);

  .user-image-wrapper {
    max-width: 27%;
    transition: visibility 0s linear, opacity 0.5s linear;
    img {
      width: 100%;
      height: 100%;
      border-radius: 9px;
    }
  }
  
  .user-info-wrapper {
    padding-left: var(--page-margin);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;

    .user-data-row {
      padding-bottom: var(--page-margin);
      flex-wrap: nowrap;
      justify-content: space-between;

      .user-name {
        margin: 0px 0px 5px;
        font-size: 20px;
        letter-spacing: 0.5px;
      }

      .user-title {
        margin: 0px;
        color: var(--ion-color-medium);
        font-size: 16px;
      }

      .membership-col {
        padding-left: var(--page-margin);
        flex-grow: 0;
      }

      .user-membership {
        display: block;
        background-color: var(--ion-color-secondary);
        color: var(--ion-color-lightest);
        border-radius: var(--app-narrow-radius);
        padding: 4px 8px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .actions-row {
      --ion-grid-columns: 10;
      --ion-grid-column-padding: var(--page-actions-padding);

      justify-content: space-between;
      flex-wrap: nowrap;
      margin-left: calc(var(--page-actions-padding) * -1);
      margin-right: calc(var(--page-actions-padding) * -1);

      .main-actions {
        flex-grow: 0;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-left: calc(var(--page-actions-padding) * -1);
        margin-right: calc(var(--page-actions-padding) * -1);
        display: flex;

        .call-to-action-btn {
          padding: 0px var(--page-actions-padding);
          margin: 0px;
        }
      }

      .secondary-actions {
        flex-grow: 0;
        padding-top: 0px;
        padding-bottom: 0px;

        .more-btn {
          --padding-start: 4px;
          --padding-end: 4px;

          margin: 0px;
        }
      }
    }
  }
}

.user-stats-section {
  --ion-grid-column-padding: 0px;

  margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
  padding-bottom: var(--page-margin);
  border-bottom: 1px solid var(--ion-color-light-shade);

  .user-stats-wrapper {
    text-align: center;

    .stat-value {
      display: block;
      padding-bottom: 5px;
      color: var(--ion-color-dark-shade);
      font-weight: 500;
      font-size: 18px;
    }

    .stat-name {
      font-size: 16px;
      color: var(--ion-color-medium);
    }
  }
}


.details-section-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--ion-color-dark-tint);
  margin: 0px 0px var(--page-margin);
}

.user-about-section {
  margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
  padding-bottom: var(--page-margin);
  border-bottom: 1px solid var(--ion-color-light-shade);

  .user-description {
    color: var(--ion-color-dark-shade);
    text-align: justify;
    margin: var(--page-margin) 0px;
    font-size: 14px;
    line-height: 1.3;
  }
}

.stories-card {
  margin: 0px;
  margin-top: var(--ion-margin, 16px);
  border-radius: 0px;

  .story-list {
    padding-top: 0px;
    padding-bottom: 0px;

    ion-thumbnail.story-avatar {
      width: 45px;
      height: 45px;
      img {
        border-radius: 10px;
      }
    }
  }

  .story-item {
    --inner-padding-bottom: 0px;
    --inner-padding-end: 0px;
    --inner-padding-start: 0px;
    --inner-padding-top: 0px;
    --padding-end: 0px;
    --padding-start: 0px;

    margin: var(--ion-margin, 8px);
    transition: all 0.1s ease-in-out;

    &:active {
      transform: scale(1.1);
    }
  }

  // .story-avatar {
  //   background: var(--ion-color-primary);
  //   height: 64px;
  //   width: 64px;
  //   padding: 2px;
  //   margin-top: 0px;
  //   margin-bottom: 0px;

  //   &.seen {
  //     background: var(--ion-color-light);
  //   }

  //   img {
  //     border: 2px solid var(--background, white);
  //     border-radius: 50%;
  //   }
  // }

  .story-author {
    text-align: center;
    margin-bottom: var(--ion-margin, 8px);
  }
}

.post-full {
  .item-top-video {
    --background: var(--ion-color-danger);
    --color: white;
  }
  .item-top-text {
    --background: var(--ion-color-tertiary);
    --color: white;
  }
  .item-top-img {
    --background: var(--ion-color-warning);
    --color: white;
  }
  .item-top-audio {
    --background: var(--ion-color-success);
    --color: white;
  }

  .cover-content2 {
    color: white;
    position: absolute;
    bottom: 29%;
    // margin-bottom: 6%;
    pointer-events: none;
  }
  .cover-flow {
    color: white;
    position: absolute;
    top: 0%;
    pointer-events: none;
    width: 100%!important;
  }
  .cover-content3 {
    color: black;
    position: absolute;
    bottom: 29%;
  }
  .cover-content4 {
    height: 200px;
    padding-top: 23%;
    color: black;
  }
  .cover-content5 {
    color: white;
    position: absolute;
    bottom: 0%;
  }
  .bottom-item {
    margin-top: -5px;
  }

  .textarea {
    width: 185px;
    margin: 0 auto;
    // white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
  }

  .view-btn {
    position: absolute;
    top: 20%;
    left: 3%;
    ion-button {
      text-transform: capitalize !important;
    }
  }

  .view2-btn {
    position: absolute;
    top: 20%;
    right: 3%;
    display: grid;
    ion-button {
      text-transform: capitalize !important;
      font-size: 12px;
    }
  }

  .view5-btn {
    position: absolute;
    top: 14%;
    left: 3%;
    ion-button {
      text-transform: capitalize !important;
    }
  }

  .view6-btn {
    position: absolute;
    top: 14%;
    right: 3%;
    display: grid;
    ion-button {
      text-transform: capitalize !important;
      font-size: 12px;
    }
  }
}

audio {
  width: 100%;
  height: 200px;
  border-radius: 0px;
  overflow: hidden;
}

.actions-btn {
  position: absolute;
  top: 8%;
  right: 3%;
  display: grid;
  margin-right: 25px;
  margin-top: 10px;
  ion-button {
    text-transform: capitalize !important;
    font-size: 12px;
  }
}  

.video-btn {
  position: absolute;
  top: 50%;
  left: 3%;
  ion-button {
    text-transform: capitalize !important;
  }
}




/* Ionic 3 version Css rules*/
ion-title{
    font-size: 12pt;
    text-align: center;
    font-weight: 600;
  }
  ion-label{
    font-size: 13pt;
  }
  .labelSmall
  {
    font-size: 9pt;
  }
  ion-note{
    font-size: 11pt;
    font-weight: normal;
  }
  
  .playButton:after 
      {
        font-family: "Ionicons";
        content: "\f487";
        
      }
  
    .pauseButton:after 
    {
      font-family: "Ionicons";
      content: "\f477";
      
    }
  
  .main { 
    display: flex;
    flex-flow: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
    height: 50%;
  
    .button-ios{
      background-color: rgba(0, 0, 0, 0.2)!important;
    } 
    .button-md{
      background-color: rgba(0, 0, 0, 0.2)!important;
    } 
  }
  
  .main1 { 
    display: flex;
    flex-flow: row nowrap;
    height: 50%;
    align-items: flex-start;
    justify-content: flex-end;
  
    .button-ios{
      background-color: rgba(0, 0, 0, 0.2)!important;
    } 
    .button-md{
      background-color: rgba(0, 0, 0, 0.2)!important;
    } 
    .button-inner{
      background-color: rgba(0, 0, 0, 0.2)!important;
    } 
  }
  
  .header-unit {
    height: 400px;
    position: relative;
    background: rgba(0, 0, 0, 0.6);
  }
  
  #video-container {
    position: absolute;
  }
  
  #video-container {
    top:0%;
    left:0%;
    height:100%;
    width:100%;
  }
  
  video.fillWidth{
    position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   -webkit-transition: all 1s;
   -moz-transition: all 1s;
   -o-transition: all 1s;
   transition: all 1s;
  
  }
  .btnPlay{
    background: rgba(0, 0, 0, 0.6);
     border: medium none;
     color: #fff;
     display: inline-block;
     font-size: 18px;
     left: 45%;
     margin: 0 ;
     padding: 8px 16px;
     position: absolute;
     right: 0;
     top: 40%;
     z-index: 10;
     zoom:1.5;
    border-radius:50%
  }
  
  .header-unitSmall {
  min-height:200px;
  position: relative;
  background: rgba(0, 0, 0, 0.6);
  }
  #video-containerSmall {
    position: absolute;
  }
  #video-containerSmall {
    top:0%;
    left:0%;
    height:100%;
    width:100%;
    overflow: hidden;
  }
  video {
//position:absolute;
  }
  video.fillWidthSmall {
    width: 100%;
    height:100%;
  }
  .ion-ios-add-circle-outline:before {
    color: #ab9333;
    zoom:1.2 !important;
    vertical-align: super !important;
   }
  .sideButtons
    {
      position: relative;
      vertical-align: middle;
      display: inline-block;
      text-align: center;
    }
      .caption{
        display: block;
        position: absolute;
        bottom: 2px;
        font-size: 10px;
      }
  .actionSheetButton {
    text-align: center;
    .sendAsMessageButton {
      background-color:  whitesmoke;
      color: #33a802;
      border-color: #33a802;
      border-left: thick solid;
      text-align: center;
    }
    .pointsMediaButton {
      background-color:  whitesmoke;
      color: #4284f3;
      border-color: #4284f3;
      border-right: thick solid;
    }
      .reviewMediaButton {
      background-color:  whitesmoke;
      color: #fabb04;
      border-color: #fabb04;
      border-left: thick solid;
    }
    .uploadVideoButton {
      background-color:  whitesmoke;
      color: #e94335;
      border-color: #e94335;
      border-left: thick solid;
    }
    .reportMediaButton {
      color: whitesmoke;
      background-color: red;
    }
    .cancelMediaButton {
      background-color:  whitesmoke;
    }
  }
  
        .Nodesktop
        {
        display: inline
        }
  
  
    
  
    #signInButton {
      background-color: whitesmoke;
      color:#333
    }
    
    #signUpButton {
      background-color: #a58a00;
      color:white
    }
      
  ion-modal {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
  }

  .circle a {
  text-align: center !important;
  text-decoration: none !important;
  }
  
  .bar {
  height: 100%;
  width: 100%;
  }
  

    #audio {
    height:65px;
    padding: 10px;
    border-radius: 10px;
    background-color: #33a802
    }
    
    #text {
    height:65px;
    padding: 10px;
    border-radius: 10px;
    background-color: #4284f3
    }

    #photo {
      height:65px;
      padding: 10px;
      border-radius: 10px;
      background-color: #fabb04;
      }
      
    
    #video {
    height:65px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e94335
    }
  
  .has-refresher>.scroll-content {
    border: none !important;
  }
  .tabbar{
  opacity: 0.9 !important;
  }
  .scroll-content
  {
  margin-bottom: 0 !important
  }
  ion-searchbar{
    padding: 0;
    font-size: 17pt !important;
      text-align: center;
  }
  
  .toggle-btn{
    background-color: #22242840;border-radius:20%
  }

  #either-gif-or-video video { display: none; }
  @media (-webkit-video-playable-inline) {
      #either-gif-or-video img { display: none; }
      #either-gif-or-video video { display: initial; }
  }
  
  .color-header {
    text-align: center;
  }

  .color-header span {
    display: block;
    padding: 5px;
    color: #fff;
  }
  
  .color-header ion-icon {
    padding: 0 10px;
    transform: rotate(45deg);
  }
  
  ion-menu ion-avatar img {
    width: 48px !important;
    height: 48px !important;
  }
  
  #icon-user {
    font-size: 45px;
  }
  
  .relative {
    position: relative;
  }
 
  
  ion-grid {
    height: 100%;
    justify-content: center;
  }
  
  .my-swal {
    background: rgba(255,255,255,.2);
    backdrop-filter: blur(2px);
  }
  
  .center {
  text-align: center;
  }
 

  .bar-custom h1.title {
  color: #fff !important;
  }
  
  
  ion-label, ion-note
      {
        margin-left: 10px
      }
  
      ion-button{
        font-weight:bolder
        }
        .action-btn {
          position: absolute;
          right: 0%;
          top: 20%;
          display: grid;
          background-color: #22242840;border-radius:5%
        }
        
        // Note:  All the CSS variables defined below are overrides of Ionic elements CSS Custom Properties
        .user-details-section {
          --ion-grid-column-padding: 0px;
        
          margin: var(--page-margin);
        
          .user-image-wrapper {
            max-width: 27%;
            transition: visibility 0s linear, opacity 0.5s linear;
            img {
              width: 100%;
              height: 100%;
              border-radius: 9px;
            }
          }
        
          .user-info-wrapper {
            padding-left: var(--page-margin);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: nowrap;
        
            .user-data-row {
              padding-bottom: var(--page-margin);
              flex-wrap: nowrap;
              justify-content: space-between;
        
              .user-name {
                margin: 0px 0px 5px;
                font-size: 20px;
                letter-spacing: 0.5px;
              }
        
              .user-title {
                margin: 0px;
                color: var(--ion-color-medium);
                font-size: 16px;
              }
        
              .membership-col {
                padding-left: var(--page-margin);
                flex-grow: 0;
              }
        
              .user-membership {
                display: block;
                background-color: var(--ion-color-secondary);
                color: var(--ion-color-lightest);
                border-radius: var(--app-narrow-radius);
                padding: 4px 8px;
                text-align: center;
                font-weight: 500;
                font-size: 14px;
              }
            }
        
            .actions-row {
              --ion-grid-columns: 10;
              --ion-grid-column-padding: var(--page-actions-padding);
        
              justify-content: space-between;
              flex-wrap: nowrap;
              margin-left: calc(var(--page-actions-padding) * -1);
              margin-right: calc(var(--page-actions-padding) * -1);
        
              .main-actions {
                flex-grow: 0;
                padding-top: 0px;
                padding-bottom: 0px;
                margin-left: calc(var(--page-actions-padding) * -1);
                margin-right: calc(var(--page-actions-padding) * -1);
                display: flex;
        
                .call-to-action-btn {
                  padding: 0px var(--page-actions-padding);
                  margin: 0px;
                }
              }
        
              .secondary-actions {
                flex-grow: 0;
                padding-top: 0px;
                padding-bottom: 0px;
        
                .more-btn {
                  --padding-start: 4px;
                  --padding-end: 4px;
        
                  margin: 0px;
                }
              }
            }
          }
        }
        
        .user-stats-section {
          --ion-grid-column-padding: 0px;
        
          margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
          padding-bottom: var(--page-margin);
          border-bottom: 1px solid var(--ion-color-light-shade);
        
          .user-stats-wrapper {
            text-align: center;
        
            .stat-value {
              display: block;
              padding-bottom: 5px;
              color: var(--ion-color-dark-shade);
              font-weight: 500;
              font-size: 18px;
            }
        
            .stat-name {
              font-size: 16px;
              color: var(--ion-color-medium);
            }
          }
        }
        
        .user-about-section {
          margin: calc(var(--page-margin) * 2) var(--page-margin) var(--page-margin);
          padding-bottom: var(--page-margin);
          border-bottom: 1px solid var(--ion-color-light-shade);
        
          .user-description {
            color: var(--ion-color-dark-shade);
            text-align: justify;
            margin: var(--page-margin) 0px;
            font-size: 14px;
            line-height: 1.3;
          }
        }
        
          // .story-avatar {
          //   background: var(--ion-color-primary);
          //   height: 64px;
          //   width: 64px;
          //   padding: 2px;
          //   margin-top: 0px;
          //   margin-bottom: 0px;
        
          //   &.seen {
          //     background: var(--ion-color-light);
          //   }
        
          //   img {
          //     border: 2px solid var(--background, white);
          //     border-radius: 50%;
          //   }
          // }
  
        .post-full {
          border-bottom: 1px solid #00000033;
          padding: 0;
          
          .media-title-btn{
            position: absolute;
            top: 72%;
            z-index: 999;
            left: 12px;
            font-weight:bold;
            background-color: #22242840
          }
          .media-time{
            position: absolute;
            top: 22%;
            z-index: 999;
            left: 12px;
            font-weight:bold
          }
        
          .activity-item {
            height: 14px;
          }
          .activity-audio {
            width: 100%;
            height: 61px !important;
          }
        
          ion-segment-button {
            --indicator-color: transparent !important;
          }
        
          .item1-top-video {
            background: var(--ion-color-danger);
            color: white;
            height: 10px;
          }
          .item1-top-text {
            background: var(--ion-color-tertiary);
            color: white;
            height: 10px;
          }
          .item1-top-img {
            background: var(--ion-color-warning);
            color: white;
            height: 10px;
          }
          .item1-top-audio {
            background: var(--ion-color-success);
            color: white;
            height: 10px;
          }
          .item-top-video {
            --background: var(--ion-color-danger);
            --color: white;
          }
          .item-top-text {
            --background: var(--ion-color-tertiary);
            --color: white;
          }
          .item-top-img {
            --background: var(--ion-color-warning);
            --color: white;
          }
          .item-top-audio {
            --background: var(--ion-color-success);
            --color: white;
          }
        
          .cover-img {
            width: 200px;
            border-radius: 5px;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background: rgba(0, 0, 0, 0.85);
            opacity: 1;
            transform: scale(1);
            transition: transform 0.5s ease, opacity 0.5s ease;
          }
          
          .cover-video {
            width: 100% !important;
            height: 500px !important;
            position: relative !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background-color: #000000cc !important;
            display:block;
          }
          .cover-content2 {
            color: white;
            position: absolute;
            bottom: 29%;
            pointer-events: none;
          }
          .cover-content3 {
            color: black;
            position: absolute;
            bottom: 29%;
          }
          .cover-content4 {
            height: 200px;
            padding-top: 23%;
            color: black;
          }
          .cover-content5 {
            color: white;
            position: absolute;
            bottom: 0%;
          }
          .bottom-item {
            margin-top: -5px;
          }
        
          .textarea {
            width: 185px;
            margin: 0 auto;
            // white-space: nowrap;
            overflow: hidden;
            box-sizing: border-box;
          }
        
          .view-btn {
            position: absolute;
            top: 20%;
            left: 3%;
            ion-button {
              text-transform: capitalize !important;
            }
          }
        
          .view2-btn {
            position: absolute;
            top: 20%;
            right: 3%;
            display: grid;
            ion-button {
              text-transform: capitalize !important;
              font-size: 12px;
            }
          }
        
          .view5-btn {
            position: absolute;
            top: 14%;
            left: 3%;
            ion-button {
              text-transform: capitalize !important;
            }
          }
        
          .view6-btn {
            position: absolute;
            top: 14%;
            right: 3%;
            display: grid;
            ion-button {
              text-transform: capitalize !important;
              font-size: 12px;
            }
          }
        }
        
        audio {
          width: 100%;
          height: 200px;
          border-radius: 0px;
          overflow: hidden;
        }
        

        .swal2-container{
          height:300px!important;
          width:300px!important;
          background: none!important;
      }
      
      .swal2-container .swal2-center .swal2-backdrop-show
      {
          background:transparent!important
      }
      .container-inner {
          position: relative;
          width: 200px;
        }
        
        .image1 {
          display: block;
          width: 200px;
          height: auto;
          border-top-right-radius: 5px;border-top-left-radius: 5px;
        }
        
        .overlay1 {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: .5s ease;
          background-color: #dfb24fb0;
        }
        
        .container-inner:hover .overlay1 {
          opacity: 1;
        }
      
        a.title{
          font-size:14px;
        }
        a.title:hover{
          text-decoration: underline;
          color:#dfb24f
        }
      .underline
      {
          color: whitesmoke;
      }
      .underline:hover{
          text-decoration: underline;
          cursor: pointer;
          font:bolder;
          color: #333;
      }
        ion-button:hover{
          cursor: pointer;
        }
        
        .text1 {
          font-size: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          text-align: center;
        }
      
      // ion-thumbnail{
      //     img{
      //         height: 45px!important;
      //         width: 45px!important;
      //     }
      // }
        /*.home-media {
          border-bottom: 1px solid
            var(
              --ion-item-border-color,
              var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))
            );
          .item-top-video {
            --color: white !important;
          }
          .item-top-text {
            --color: black !important;
          }
          .item-top-img {
            --color: white !important;
          }
          .item-top-audio {
            --color: black !important;
          }
        
          .avatar-item {
            top: 4%;
            position: absolute;
            --background: transparent;
            --color: white;
          }
          .video {
            height: 350px;
          }
          .video-content {
            position: absolute;
            bottom: 16%;
            color: white;
            pointer-events: none;
          }
          .image {
            width: 100%;
            height: 350px;
          }
          .image-content {
            position: absolute;
            bottom: 3%;
            color: white;
            pointer-events: none;
          }
          .audio {
            height: 208px;
            display: grid;
            margin-bottom: 17%;
          }
          .audio-content {
            position: absolute;
            bottom: 3%;
            pointer-events: none;
          }
          .text-content {
            margin-top: 16%;
            margin-right: 13%;
          }
          .action-btn {
            position: absolute;
            right: 0%;
            top: 4%;
            display: grid;
          }
        }*/
  
  div.backPhoto
  {
  height: 24px; 
  position: relative; 
  margin-top: -15px; 
  margin-left: 10px; 
  display: block;
  }
  .backPhoto img
  {
    background: #fff;
    border: 1px solid #fff;
    -moz-border-radius: 50%;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: 50px;
    line-height: 50x;
    margin-left: -10px;
    margin-top: 0px;
    position: relative;
    width: 50px;
  }
  
  .backPhoto span
  {
    vertical-align:150%;font-size:15px;
    color: whitesmoke;
    font-weight: bold;
  }
  
  h2 {
  content: "\00A0";
  }
  
  .round
  {
  display: block !important;
  line-height: 50px !important;
  border: 2px solid white !important;
  border-radius: 50% !important;
  color: white !important;
  text-align: center !important;
  text-decoration: none !important;
  font-weight: bold !important;text-transform: lowercase;
  }
  
  .mainImage
  {
  line-height:4em;
  -webkit-box-shadow: 1px 1px 3px 3px rgba(245, 245, 245, 0.4);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    1px 1px 3px 3px rgba(245, 245, 245, 0.4);  /* Firefox 3.5 - 3.6 */
  box-shadow:         1px 1px 3px 3px rgba(245, 245, 245, 0.4); 
  width: 100%;
  height:100%
  }
  
  .col-1 {width: 8.33%;}
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
  
  
  .ellipsis
  {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  .ellipsisTwo
  {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 120px;
  }
  .ellipsisThree
  {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  
  
  
  
    .main { 
        display: flex;
        flex-flow: column-reverse;
        height: 50%;
      
        .button-ios {
          background-color: rgba(0, 0, 0, 0.2)!important;
        } 
        .button-md {
          background-color: rgba(0, 0, 0, 0.2)!important;
        } 
      }
  
     .main1 { 
        display: flex;
        flex-flow: row nowrap;
        height: 50%;
      
        .button-ios {
          background-color: rgba(0, 0, 0, 0.2)!important;
        } 
        .button-md {
          background-color: rgba(0, 0, 0, 0.2)!important;
        } 
      }
  
    #video-container {
        position: absolute;
    }
    #video-container {
        top:0%;
        left:0%;
        height:100%;
        width:100%;
    }
  
    #video-containerSmall {
        position: absolute;
    }
    #video-containerSmall {
        top:0%;
        left:0%;
        height:100%;
        width:100%;
        overflow: hidden;
    }
  
    .async {
      transition: all .5s ease-in-out;
      column-gap: 0px;
    }
    
    /* async item */
    .asyncItem {
      display: inline-block; /* Fix the misalignment of items */
      vertical-align: top; /* Keep the item on the very top */
      width:initial
    }
    
    /* async image effects */
    .async .asyncItem img {
      transition: all .5s ease-in-out;
      backface-visibility: hidden; /* Remove Image flickering on hover */
    }
    
    .async .asyncItem:hover img {
      opacity: .75;
    }
    
    /* Bordered async */
    .async.bordered {
      column-rule: 1px solid #eee;
      column-gap: 20px;
    }
    
    .async.bordered .asyncItem {
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid #eee;
    }
    
   /* Gutterless masonry */
  .async .gutterless {
    column-gap: 0;
  }
  
  .async.gutterless .asyncItem {
    margin-bottom: 0;
  }
    
    /* async on tablets */
    @media only screen and (min-width: 0px) and (max-width: 1023px) {
      .async {
        column-count: 2;
      }
      .async1 {
        column-count: 1;
      }
    }
    
    /* async on big screens */
    @media only screen and (min-width: 1024px) {
      .async {
        column-count: 3;
      }
  }


.masonry-container {
  column-count: 3;
  column-gap: 15px;
 }
 .masonry-item {
  display: inline-block;
  width: 100%;
  border: 1px solid #33333342;
  border-radius: 5px;
  margin: 5px;
 }
 .masonry-item img.masonryImage {
  display:block;
  width: 100%;
 }
  
  /* what color you like ? what do you say? and ion-header too ok */
  ion-content {
    --background: whitesmoke;
  }
  .swal2-container .swal2-center .swal2-backdrop-show
  {
      background:transparent!important
  }

  .swal2-icon.swal2-info {
    border-color: #dfb24f !important;
    color: #dfb24f !important;
  }
  .swal2-deny {
    background-color: whitesmoke!important;
    color: #333 !important;
    border: none!important;
  }
  .swal2-confirm {
    background-color:  #333!important;
    color: whitesmoke !important;
    border: none!important;
  }
  
  .bottom-drawer {
    position: fixed;
    display: block;
    flex-direction: column;
    justify-content: flex-end;
    bottom: -100%;
    background:#00000064;
    transition: 350ms;
    height:100%;
    overflow:auto;
    width: 100%;
    z-index: 99;

    &.open {
      bottom: 5%;
      z-index: 1000000;
    }
    .inner {
      max-height:80%;
      position:absolute;
      width:100%;
      bottom: 0;
      overflow:auto;
      background-color: white;
      border-radius: 20px 20px 0 0;

      .close-btn {
        display: flex;
  
        span {
          background: #424242;
          height: 5px;
          width: 50px;
          margin: auto;
          border-radius: 5px;
        }
      }
    }
  }

  .menu-btn {
    background-color: #242424;
    display: flex;
    padding: 8px 0 28px 0;
    margin-bottom: -15px;
    border-radius: 20px 20px 0 0;

    span {
      background: #424242;
      height: 5px;
      width: 50px;
      margin: auto;
      border-radius: 5px;
    }
  }
  
  .search-input {
    padding: 6px;
      margin-top: 24px;
      font-size: 17px;
      border: 2px solid whitesmoke;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    margin-left: 10px;
    color:rgb(46, 45, 45);
    }
    .search-input::placeholder {
    color: peachpuff;
    font-size: 13px;
    font-family: Arial, Helvetica, sans-serif;;
    }
  
    .search-container .button-search {
    padding: 8px;
      margin-right: 16px;
      background: #dfb24f;
      font-size: 13px;
      font-weight: bolder;
      border: none;
      cursor: pointer;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    
    .search-container .button-search:hover {
    background: whitesmoke;
    }
    
    @media screen and (max-width: 600px) {
    .search-container {
      float: none;
    }
    .search-input, .search-container .button-search {
      float: none;
      display: block;
      text-align: left;
      width: 100%;
      margin: 0;
      padding: 14px;
    }
    .search-input {
      border: 1px solid whitesmoke;  
    }
  }

  .carouselTitle {
    background-color: rgba(0, 0, 0, 0.5);
  /*positioned relative to parent div (container) */
    position: absolute;    
  /* bottom margin is 0 so that it 
  coincides with container's bottom margin*/
    bottom: 0;             
    color: whitesmoke;
    width: 100%;
    font-size: 11px;
    padding: 15px 10px;
    text-align: center;
    /*invisible because opacity is 0*/
    opacity: 0;            
    transition: 0.6s;
  }
  
  .carousel-cell:hover .carouselTitle {
  /*becomes visible on hover*/ 
    opacity: 1;          
  }
